@import '../../styles/customMediaQueries.css';

/* Order form inside modalContainer needs special handling */
/* since "inMobile" breakpoint is actually --viewportLarge */
.modalContainer {
  /* composes: marketplaceModalInMobileBaseStyles from global; */
  width: 100%;
  height: 50vh;
  justify-content: center;
  background-color: var(--colorWhite);
  margin: auto;

  @media (--viewportMedium) {
    padding: var(--modalPaddingMedium);
    /* background-color: var(--colorWhite); */
    /* flex-basis: 576px; */
    height: 100vh;
    /* margin-top: 12.5vh; */
    /* margin-bottom: 12.5vh; */
  }
}

.searchForm {
  background-color: white;
  padding: 20px 24px 20px;
  border-radius: 32px;
  align-items: center;
  gap: 20px;
  display: none;

  @media (min-width: 769px) {
    display: flex;
  }
}

.mobileSearchForm {
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.textContent {
  padding: 40px 20px;
  max-width: 1440px;
  margin: auto;

  @media (--viewportLarge) {
    padding: 80px 150px 60px;
  }
}

.searchFlexContent {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 32px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  flex: 1;
  display: flex;
  /* Make the flex item a flex container */
  flex-direction: column;
  /* Arrange content vertically */
  justify-content: center;
  /* Center content vertically within the item */

  @media (min-width: 758px) {
    margin: 32px 0px;
  }
}
.dataPoint{
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 32px;
  /* box-shadow: 0 4px 8px 0 rgb(253, 253, 253); */
  background-color: #363535;
  width: 100%;
  flex: 1;
  display: flex;
  /* Make the flex item a flex container */
  flex-direction: column;
  /* Arrange content vertically */
  justify-content: center;
  /* Center content vertically within the item */

  /* @media (min-width: 758px) {
    margin: 32px 0px;
  } */
}
.dataPointTitle{
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  /* margin-bottom: 15px; */
}
.data{
  padding: 20px 0;
    margin-bottom: 15px; 
  color:#fff;
  font-size: 72px;
  font-weight: 500;
  align-items: center;
}



.flexContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  margin: 20px 0px;
  padding-top: 12px;

  @media (min-width: 758px) {
    justify-content: space-between;
    flex-direction: row;
    margin: 0px;
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.ctaButton {
  composes: align;
  display: inline-block;
  padding: 16px 20px;
  font-size: 16px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;
  width: 100%;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

.searchFlexTitle {
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
}

.searchFlexSub {
  padding: 12px 0px;
  color: #4a4a4a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.titleContent {
  color: var(--Neutral-grey-4, #17181a);

  /* Display xl/Regular */
  font-family: 'Poppins';
  font-size: 24px;
  font-style: normal;
  /* font-weight: 700; */
  line-height: normal;

  @media (--viewportMedium) {
    font-size: 32px;
  }
}

.searchTextContent2 {
  /* text-align: center; */
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 12px;
}

.flexWithWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  padding: 20px 0px;
}

.searchSmallContent {
  flex: '1 1 calc(50% - 16px)';

  @media (--viewportLarge) {
    flex: '1 1 calc(25% - 16px)';
  }
}

.normalCtaButton {
  composes: align;
  display: inline-block;
  padding: 16px 20px;
  font-size: 16px;
  background-color: var(--marketplaceColor);
  border-radius: 4px;
  color: white;
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }
}

.companyKeyContent {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  background: #fff;

  /* box */
  box-shadow: 0px 4px 250px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  min-height: 136px;
  width: 100%;

  @media (--viewportMedium) {
    flex: '1 1 calc(50% - 16px)';
    flex: 1 0 0;
  }

  @media (--viewportLarge) {
    flex: '1 1 calc(25% - 16px)';
  }
}

.companyKeyTitle {
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.paddingContent {
  padding: 0px 20px 40px;
  max-width: 1440px;
  margin: auto;

  @media (--viewportLarge) {
    padding: 0px 150px 80px;
  }
}

.membershipContent {
  padding: 48px;
  /* max-width: 45%; */
}

.membershipBack {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  /* margin: auto; */
  background-color: black;
  color: white;
  padding: 20px 0px;

  @media (min-width: 400px) {
    padding: 0px;
    min-height: 383px;
  }
}

.membershipTitle {
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 20px;
}

.membershipSub {
  text-align: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin: auto;

  @media (--viewportMedium) {
    width: 60%;
  }
}

.asset {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* min-height: 383px; */
  background-color: rgb(246, 246, 246);
  padding: 20px 0px;

  @media (min-width: 400px) {
    padding: 0px;
    min-height: 383px;
  }
}

.assetContent {
  padding: 36px 48px;
  max-width: 960px;
  /* border: 1px solid black; */
  border-radius: 8px;
  background-color: white;
  box-shadow: 0px 4px 250px 3px rgba(0, 0, 0, 0.1);
  width: 88%;
}

.customCtaButton {
  composes: align;
  display: inline-block;
  padding: 16px 20px;
  font-size: 16px;
  background-color: white;
  border-radius: 4px;
  color: var(--marketplaceColor);
  text-decoration: none;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 20%);
  font-weight: 500;
  margin-top: 24px;

  &:hover {
    text-decoration: none;
    /* background-color: va; */
  }
}

.mobileTopic {
  height: 400px !important;
  background-image: url(../../assets/coverimage.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  color: white;
  padding: 40px 0px;

  @media (--viewportLarge) {
    display: none;
  }
}

.topbarSub {
  text-align: center;

  font-style: normal;
  font-weight: 400;
  line-height: normal;

  padding: 32px 0px 8px;
  margin: auto;
  /* white-space: nowrap; */

  @media (--viewportMedium) {
    font-size: 20px;
    width: fit-content;
  }
}

.desktopDisplay {
  display: none;

  @media (--viewportMedium) {
    display: block;

  }
}

.mobileDisplay {
  display: block;

  @media (--viewportMedium) {
    display: none;

  }
}

.mobileImage {
  max-width: 88%;
  /* margin-top: 14%; */

  @media (min-width: 600px) {
    /* margin-top: 8%; */
  }
}

.movecontentUpwards {
  @media (--viewportLarge) {
    margin-top: 40vh;
  }
}

.entirePage {
  background-color: rgb(246, 246, 246);
}

.checkResolution {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 2000;
}

.sectionCompanyContent {
  /* display: flex; */
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  @media (--viewportLarge) {
    display: flex;
  }
}

.gridcontainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  /* 4 columns */
  gap: 20px;
  /* Adjust spacing between items */
  flex: 1;

  @media (--viewportMedium) {
    grid-template-columns: repeat(4, 1fr);
    /* 4 columns */
    gap: 10px;
  }
}

.griditem {
  padding: 0px 20px;

  @media (--viewportMedium) {
    margin: auto;

  }
}

.gridContent {
  padding: 32px 0px 0px;
  min-height: 280px;
  display: flex;
  justify-content: center;
}

.smallContentTitle {
  font-weight: bold;
  font-size: 18px;
}

.sectionCompanyLeftContent {
  @media (--viewportLarge) {
    width: 50%;
  }
}

.sectionFirst {
  display: block;
  height: calc(100vh - 160px);
  background-image: url(../../assets/coverimage.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width:2000px) {
    /* height: 100vh; */
  }
}

.customSearchActivity {
  /* display: none; */
  position: fixed;
  width: 100%;
  max-width: 100vw;
  overflow: hidden;
  top: 60px;
  left: 0;
}


.customLandingWeeklyActivity {
  /* display: none; */
  width: 100%;
  max-width: 100vw;
  margin: 0px auto 0px;
  /* position: sticky; */
  top: 60px;

  @media (--viewportLarge) {
    top: 72px;
  }
}

.homePageTopbarContent {
  text-align: center;
  color: white;
  padding: 60px 0px 0px;
  /* flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column; */
  margin: auto;
  width: 90%;

  @media (--viewportMedium) {
    width: fit-content;
  }
}

.desktopOnly {
  display: none;

  @media (--viewportLarge) {
    display: inherit;
  }
}

.mobileOnly {
  display: inherit;

  @media (--viewportLarge) {
    display: none;
  }
}